.createTaskBox, .createTaskForm {
  display: flex;
  border-radius: 12px;
  flex-direction: column;
  align-items: center;
  width: 400px;
  max-height: 50vh;
}

.createTaskBox {
  overflow-y: scroll;
  overflow-x: hidden;
}

.createTaskForm * {
  width: 90%;
  padding: 10px;
}

input, textarea {
  width: 80%;
  border-radius: 12px;
}

textarea {
  resize: none;
  height: 5vh;
  overflow-y: scroll;
  overflow-x: hidden;
}
