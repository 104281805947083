.member {
  border: 1px solid white;
  border-radius: 12px;
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 5px;
}

.memberUser {
  width: fit-content;
}

.member:hover {
  transition: all .25s;
  background-color: rgba(211, 211, 211, 0.445);
  cursor: pointer;
}

.assigneesBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 400px;
}

.currentAssigneeBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.projectMembersBox, .allAssigneesBox {
  max-height: 200px;
  overflow-y: scroll;
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
