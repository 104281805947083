.splashPic {
  height: 50%;
  width: 50%;
  border-radius: 12px;
}

.splashBackground {
  display: flex;
  flex-direction: row;
  margin: 25px;
  gap: 25px;
  justify-content: space-around;
  padding-bottom: 60px;
}

.demoButton {
  border: none;
  padding: 15px;
  font-size: x-large;
  border-radius: 12px;
  background-color: white;
  color: black;
  cursor: pointer;
  margin: 5px;
  width: fit-content;
}

.leftSplashBox {
  max-width: 450px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.smallHr {
  width: 50%;
}
