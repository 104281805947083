.editTaskBox, .editTaskForm {
  display: flex;
  border-radius: 12px;
  flex-direction: column;
  align-items: center;
  width: 400px;
  max-height: 50vh;
}

.editTaskBox {
  overflow-y: scroll;
  overflow-x: hidden;
}

.editTaskForm * {
  width: 90%;
  padding: 10px;
}
