.profile-dropdown {
  position: absolute;
  background-color: #3E3E40;
  color: white;
  right: 0px;
  top: 55px;
  border: rgb(88, 84, 84) 1px solid;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  border-radius: 12px;
}

.logo {
  margin-left: 5px;
}

.userDetails {
  gap: 10px;
}

.hidden {
  display: none;
}

.NavBar {
  background-color: #3E3E40;
  height: 55px;
  position: sticky;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: rgb(88, 84, 84) 1px solid;
}

.aboutBar {
  background-color: #3E3E40;
  height: 55px;
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-bottom: rgb(88, 84, 84) 1px solid;
  bottom: 0px;
  width: 100%;
}

.profileButton {
  border: none;
  border-radius: 12px;
  height: 50px;
  /* width: 50px; */
  gap: 10px;
  cursor: pointer;
  background-color: transparent;
  color: white;
}

.about {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px
}
