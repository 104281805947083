.currentMemberBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 400px;
}

.membersBox {
  overflow-y: scroll;
  overflow-x: hidden;
}

.membersBox, .membersForm {
  display: flex;
  border-radius: 12px;
  flex-direction: column;
  align-items: center;
  width: 400px;
  max-height: 50vh;
}

.membersForm * {
  width: 90%;
  padding: 10px;
}
