.homePage {
  background-color: #1E1F21;
  margin: 2vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 60px;
}

.homeBar {
  width: 100%;
}

.allProjectsBox {
  border: 1px solid;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 50%;
  background-color: #3E3E40;
  padding: 15px;
  border-radius: 12px;
}

.logo {
  height: 30px;
  width: auto;
  padding: 10px 0px 0px 20px;
}

.projectBox {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  /* border: #1E1F21 1px solid; */
  padding: 10px;
  width: fit-content;
  border-radius: 12px;
}

.createProjectButton {
  border: none;
  padding: 10px;
  font-size: medium;
  border-radius: 12px;
  background-color: white;
  color: black;
  cursor: pointer;
  margin: 5px;
}

.createProjectButton:hover {
  transition: all .25s;
  background-color: rgb(133, 129, 129);
  color: white;
}

.projectDropdown {
  background-color: #1E1F21;
  position: absolute;
  padding: 5px;
  border-radius: 12px;
}

.fa-list-check {
  color: white;
}

.createProjectDiv {
  padding: 10px;
  display: flex;
  align-items: center;
}
